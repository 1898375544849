<template>
  <div class="row">
    <div class="col">
      <b-card>
        <h2>Test Instructions</h2>
        <p>Some instructions ....</p>
        <hr />
        <div class="text-right">
          <div class="d-sm-inline-block mt-2">
            <button class="btn btn-outline-primary btn-block" @click="startTest" title="Start the test now">
              <b-spinner small v-if="loading" />
              Start Test
            </button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import settings from '@/app.config';

export default {
  name: 'TestInstruction',

  data() {
    return {
      book_id: this.$route.params.book_id,
      loading: false,
    };
  },

  methods: {
    startTest() {
      if (this.book_id) {
        const testDetails = {
          book_id: this.book_id,
          quiz_count: settings.testQuestionsCount,
          time_allowed: settings.testAllowedDuration,
        };
        this.loading = true;
        this.$store.dispatch('test/createTest', testDetails).then((test) => {
          this.$router.push({ name: 'test-question', params: { book_id: this.book_id, test_id: test.id } });
        }).catch(() => {
          this.$toast.error('Failed to start the test. Please, try again later.');
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },

};
</script>
